import React from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import Layout from "../components/Layout"
import Seo from "../components/SEO"

const SolutionTemplate = ({ pageContext: { solution } }) => {
  return (
    <Layout>
      <Seo
        title={
          solution.pageContent.seoTitle || `${solution.title} - Doganbros.com`
        }
        description={
          solution.pageContent.seoDescription || `${solution.description}`
        }
        keywords={solution.pageContent.seoKeywords}
      />
      <section id="solution-template" className="section">
        <div className="container mb-6">
          <h3 className="title has-text-primary mb-6">{solution.title}</h3>
          <div>
            <div className="content block">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {solution.pageContent.content}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SolutionTemplate
